import React, { useEffect, useMemo, useRef, useState } from "react"
import { Loader, StatusTag } from "../../../shared"
import { Button, Flex, Spin, Table, Typography } from "antd"
import TimeDisplay from "../../TimeDisplay"
import PropTypes from "prop-types"
import { CheckCircleOutlined } from "@ant-design/icons"
import { API } from "../../../../App"
import { useOrder } from "../../../shared/hooks/UseOrder"
import { CSVLink } from "react-csv"
import { Link } from "react-router-dom"
import moment from "moment"

const statusColorMap = {
	// START: 'default',
	// FAIL: 'red',
	// PROCESSING: 'orange',
	// PROGRESS: 'blue',
	// COMPLETE: 'green',

	NONE: "default",
	START: "default",
	FAIL: "default",
	PROCESSING: "default",
	PROGRESS: "default",
	COMPLETE: "default",
}

const otaProcessLogStatus = {
	NONE: "none",
	START: "start",
	FAIL: "fail",
	PROCESSING: "processing",
	COMPLETE: "complete",
	PROGRESS: "progress",
}

const otaProcessLogStatusLabel = {
	NONE: "None",
	START: "Start",
	FAIL: "Fail",
	PROCESSING: "Processing",
	COMPLETE: "Complete",
	PROGRESS: "Progress",
}

const dispenserStatus = {
	IDLE: "IDLE",
	DOWNLOADING: "DOWNLOADING",
	UNPACK: "UNPACK",
	READY_TO_APPLY: "READY_TO_APPLY",
	APPLY_UPDATE: "APPLY_UPDATE",
	FINISH: "FINISH",
	ERROR: "ERROR",
}

const order = ["Error", "Completed", "None", "Finish"]

const columns = [
	{
		title: "Customer",
		dataIndex: "customerName",
		key: "customerName",
	},
	{
		title: "Dispenser",
		dataIndex: "dispenserHierarchy",
		key: "dispenserHierarchy",
		render: (_, { dispenserHierarchy, storeId }) => {
			const previousYear = moment().subtract(1, "year").startOf("year").format()
			const today = moment().endOf("day").format()

			return (
				dispenserHierarchy && (
					<Link to={`/store?dispenserID=${storeId}&dateRangeStart=${previousYear}&dateRangeEnd=${today}`}>
						<Typography.Link style={{ fontSize: "0.8rem", textWrap: "nowrap", overflow: "hidden" }} level={5}>
							{dispenserHierarchy}
						</Typography.Link>
					</Link>
				)
			)
		},
	},

	{
		title: "Firmware Version",
		dataIndex: "firmwareVersion",
		key: "firmwareVersion",
	},

	{
		title: "DB Version",
		dataIndex: "dbVersion",
		key: "dbVersion",
	},

	{
		title: "OTA Status",
		dataIndex: "otaStatus",
		key: "otaStatus",
		sorter: true,
		render: (_, { otaStatus: status }) =>
			status && <StatusTag statusColorMap={statusColorMap} status={otaProcessLogStatusLabel[status.toUpperCase()]} />,
	},

	{
		title: "OTA Status Time",
		dataIndex: "otaStatusTime",
		key: "otaStatusTime",
		sorter: true,
		render: (_, { otaStatusTime }) => otaStatusTime && <TimeDisplay date={moment.utc(otaStatusTime)} />,
	},

	// {
	// 	title: "Dispenser Status",
	// 	dataIndex: "dispenserStatus",
	// 	key: "dispenserStatus",
	// 	sorter: true,
	// 	render: (_, { dispenserStatus: status }) => status && <StatusTag statusColorMap={statusColorMap} status={status} />,
	// },

	// {
	// 	title: "Dispenser Status Time",
	// 	dataIndex: "dispenserStatusTime",
	// 	key: "dispenserStatusTime",
	// 	sorter: true,
	// 	// render: (_, { dispenserStatusTime }) => <TimeDisplay date={dispenserStatusTime} />,
	// 	render: (_, { dispenserStatusTime }) => "",
	// },
]
const DispensersDetails = ({ selectedProcessId, selectedTab }) => {
	//Rename data for exact OTA
	const [page, setPage] = useState(0)
	const [order, handleOrder] = useOrder("otaStatusTime")

	const [filter, setFilter] = useState()
	const [loading, setLoading] = useState()
	const [isErrorOnly, setIsErrorOnly] = useState()
	const [pageSize, setPageSize] = useState(10)
	const [dispensers, setDispensers] = useState()

	const [customers, setCustomers] = useState([])

	const [csvLoading, setCSVLoading] = useState(false)

	const exportOTALogs = async () => {
		setCSVLoading(true)

		const res = await API.exportOTALogs(selectedProcessId)

		if (res.ok) {
			const url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement("a")
			link.href = url
			const fileName = `ota_logs_${moment().format("MM-DD-YYYY")}.csv`
			link.setAttribute("download", fileName)
			document.body.appendChild(link)
			link.click()
			link.remove()
		}

		setCSVLoading(false)
	}

	const handleErrorsOnly = () => {
		if (filter?.otaStatus === otaProcessLogStatus.FAIL || filter?.dispenserStatus === dispenserStatus.ERROR) {
			setFilter()
			setIsErrorOnly()
		} else {
			const newFilter = filter || {}

			newFilter["otaStatus"] = otaProcessLogStatus.FAIL
			newFilter["dispenserStatus"] = dispenserStatus.ERROR

			setFilter(newFilter)
			setIsErrorOnly(true)
		}
	}

	useEffect(() => {
		const fetchCustomers = async () => {
			const response = await API.getStoreNames()

			if (response && response.ok && response.data) {
				response.data.splice(0, 1)
				setCustomers(response.data)
			}
		}

		fetchCustomers()
	}, [])

	const handlePagination = (page, pageSize) => {
		setPage(page - 1)
		setPageSize(pageSize)
	}

	console.log("selectedTab", selectedTab)

	useEffect(() => {
		console.log("refresh")

		setLoading(true)

		API.getOTALogs(selectedProcessId, {
			offset: page * pageSize,
			limit: pageSize,
			...order,
			...(filter && { filter: { where: filter } }),
		})
			.then((res) => {
				setLoading(false)

				if (res.data && res.ok) {
					setDispensers(res.data)
				}
			})
			.catch((err) => {
				setLoading(false)
				console.log("err", err)
			})

		return () => setDispensers()
	}, [selectedTab, selectedProcessId, order.sort, order.order, page, pageSize, filter])

	return (
		<Flex gap="middle" vertical style={{ maxWidth: "100%" }}>
			<Flex gap="middle" justify="flex-end" align="flex-end" style={{ maxWidth: "100%", flexWrap: "wrap" }}>
				<Button
					type="primary"
					onClick={() => handleErrorsOnly()}
					style={{
						display: "flex",
						gap: "0.1rem",
						alignItems: "center",
						justifyContent: "center",

						transition: "all 0.3s linear",
						...(isErrorOnly && {
							borderColor: "#b7eb8f",
							backgroundColor: "#f6ffed",
							color: "#389e0d",
						}),
					}}
				>
					<CheckCircleOutlined className={!isErrorOnly ? "error_only__disabled" : ""} />
					Errors Only
				</Button>

				<Button type="primary" disabled={csvLoading} onClick={() => exportOTALogs()}>
					{csvLoading ? <Spin size="small" /> : "Export Log"}
				</Button>

				{/* <CSVLink filename='ota_logs.csv' ref={csvLink} data={csvData.rows} headers={csvData.headers} target='_blank' /> */}
			</Flex>

			{loading ? (
				<Loader />
			) : (
				<>
					{dispensers && (
						<Table
							onChange={(_a, _b, sorting) =>
								handleOrder({
									order: sorting.order,
									sort: ["otaStatus", "dispenserStatus"].includes(sorting.columnKey)
										? `${sorting.columnKey}Priority`
										: sorting.columnKey,
								})
							}
							pagination={{
								className: "pagination",
								showSizeChanger: false,
								position: ["bottomRight"],
								total: dispensers?.total,
								pageSize: pageSize,
								current: page + 1,
								onChange: (page, pageSize) => handlePagination(page, pageSize),
							}}
							scroll={{ x: true }}
							columns={columns}
							dataSource={dispensers?.rows}
							rowKey={(item) => item.id}
						/>
					)}
				</>
			)}
		</Flex>
	)
}

DispensersDetails.propTypes = {
	selectedProcessId: PropTypes.string.isRequired,
	selectedTab: PropTypes.string.isRequired,
}

export default DispensersDetails
