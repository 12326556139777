import React, { useState } from "react"
import { Flex, Tabs } from "antd"
import StatusDetails from "./components/StatusDetails"
import DispensersDetails from "./components/DispensersDetails"
import PropTypes from "prop-types"
import Statistic from "./components/Statistic"

const { TabPane } = Tabs

const DetailsTabs = ({ selectedProcessId }) => {
	const [tab, setTab] = useState(null)

	return (
		<Flex
			vertical
			justify="flex-start"
			align="flex-start"
			gap={"large"}
			style={{ width: "100%", minHeight: "400px", marginTop: "-25px" }}
		>
			<Tabs onChange={setTab} defaultActiveKey="statusDetails" type="card" size="large" style={{ width: "100%" }}>
				<TabPane key="statusDetails" tab="Status Details">
					<StatusDetails selectedProcessId={selectedProcessId} />
				</TabPane>

				<TabPane key="dispensers" tab="Dispenser Details">
					<DispensersDetails selectedTab={tab} selectedProcessId={selectedProcessId} />
				</TabPane>

				<TabPane key="statistic" tab="Statistics">
					<Statistic selectedProcessId={selectedProcessId} />
				</TabPane>
			</Tabs>
		</Flex>
	)
}

DetailsTabs.propTypes = {
	selectedProcessId: PropTypes.string.isRequired,
}

export default DetailsTabs
