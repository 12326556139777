import React, { useEffect, useMemo, useState } from "react"
import { Checkbox, Flex, Popover, Typography, Input, List, Divider } from "antd"
import { EditOutlined } from "@ant-design/icons"
import TooltipIconButton from "../../../shared/TooltipIconButton"
import StatsItem from "../../StatsItem"
import PropTypes from "prop-types"
import { Loader } from "../../../shared"
import { observer } from "mobx-react"
import { useStore } from "../../../../stores"
import EditVersionModal from "../../../shared/modals/EditVersionModal"

function bytesToMegabytes(bytes) {
	const megabytes = bytes / (1024 * 1024) // Convert bytes to megabytes
	return megabytes.toFixed(1) // Format to 1 decimal place
}

const { TextArea } = Input

const VersionDetails = observer(({ selectedVersionId }) => {
	const { versionDetailsStore } = useStore()
	const [openEditVersionModal, setOpenEditVersionModal] = useState(false)

	const details = useMemo(() => versionDetailsStore.versionDetails, [versionDetailsStore.versionDetails])

	const fetchVersionDetails = async (id) => {
		await versionDetailsStore.getDetails(id)
	}

	useEffect(() => {
		fetchVersionDetails(selectedVersionId)

		return () => versionDetailsStore.clearDetails()
	}, [selectedVersionId])

	const onEditClick = () => {
		setOpenEditVersionModal(true)
	}
	const handleEditVersionModalClose = () => {
		setOpenEditVersionModal(false)
		fetchVersionDetails(selectedVersionId)
	}

	if (versionDetailsStore.inProgress) return <Loader />
	if (!details) return

	return (
		<>
			<Flex justify="flex-start" align="flex-start" gap={"middle"} style={{ width: "100%" }} vertical>
				<Typography.Title level={4}>{details.name}</Typography.Title>
				<Flex justify="flex-start" align="flex-start" gap={"0.7rem"} style={{ width: "100%" }}>
					<TextArea
						rows={4}
						disabled
						placeholder="Comments..."
						style={{ resize: "none", width: "70%" }}
						value={details.comments}
					/>
					<TooltipIconButton onClick={() => onEditClick()} icon={<EditOutlined />} text="Edit" />
				</Flex>


				<Flex justify="flex-start" align="flex-start" gap={"2rem"} style={{ width: "100%" }}>
				<Flex justify="flex-start" vertical align="flex-start" gap={"2rem"} style={{ width: "50%" }}>
				<Flex justify="flex-start" align="flex-start" gap={"0.5rem"} style={{ width: "100%" }}>
						<StatsItem
							value={details.firmwareVersion || "Not Set"}
							color={"rgba(0, 0, 0, 0.25)"}
							title={"Firmware Version"}

							titleStyles={{
								fontWeight: "normal",
							}}
						/>
						<StatsItem
							value={details.firmwareVersionSize ? `${bytesToMegabytes(details.firmwareVersionSize)} Mb` : "Not Set"}
							color={ "rgba(0, 0, 0, 0.25)"}
						/>
					</Flex>
				<List
						className="firmware-drop-list"
						header={<div>Firmware version uploaded files</div>}
						bordered
						disabled
						style={{
							backgroundColor: "#f5f5f5", // Gray background for the entire list
							pointerEvents: "none", // Disable interaction for the entire list
							opacity: 0.6, // Slight opacity for a disabled effect
						}}
						dataSource={details?.firmwareVersionFileNames || []}
						renderItem={(item, idx) => (
							<List.Item
								disabled
								style={{
									overflow: "hidden",
									textWrap: "nowrap",
									position: "relative",
									backgroundColor: "#f5f5f5", // Gray background
									pointerEvents: "none", // Disable interaction
									opacity: 0.6, // Slight opacity for a disabled effect
								}}
							>
								<span style={{ width: "90%", textWrap: "nowrap", overflow: "hidden" }}>
									{idx + 1}. {item}
								</span>
							</List.Item>
						)}
					/>
					</Flex>
					<Flex justify="flex-start" vertical align="flex-start" gap={"2rem"} style={{ width: "50%" }}>
					<Flex justify="flex-start" align="flex-start" gap={"0.5rem"} style={{ width: "100%" }}>
						<StatsItem
							value={details.databaseVersion || "Not Set"}
							color={"rgba(0, 0, 0, 0.25)"}
							title={"DB Version"}
							titleStyles={{
								fontWeight: "normal",
							}}
						/>
						<StatsItem
							value={details.databaseVersionSize ? `${bytesToMegabytes(details.databaseVersionSize)} Mb` : "Not Set"}
							color={"rgba(0, 0, 0, 0.25)"}
						/>
					</Flex>
					<List
						className="firmware-drop-list"
						header={<div>Database version uploaded files</div>}
						bordered
						disabled
						style={{
							backgroundColor: "#f5f5f5", // Gray background for the entire list
							pointerEvents: "none", // Disable interaction for the entire list
							opacity: 0.6, // Slight opacity for a disabled effect
						}}
						dataSource={details?.databaseVersionFileNames || []}
						renderItem={(item, idx) => (
							<List.Item
								disabled
								style={{
									overflow: "hidden",
									textWrap: "nowrap",
									position: "relative",
									backgroundColor: "#f5f5f5", // Gray background
									pointerEvents: "none", // Disable interaction
									opacity: 0.6, // Slight opacity for a disabled effect
								}}
							>
								<span style={{ width: "90%", textWrap: "nowrap", overflow: "hidden" }}>
									{idx + 1}. {item}
								</span>
							</List.Item>
						)}
					/>
					</Flex>
				</Flex>
			</Flex>
			{openEditVersionModal && (
				<EditVersionModal initialState={details} isOpen={openEditVersionModal} onCancel={handleEditVersionModalClose} />
			)}
		</>
	)
})

VersionDetails.propTypes = {
	selectedVersionId: PropTypes.string.isRequired,
}

export default VersionDetails
