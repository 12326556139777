import { Button, Flex, Popover } from "antd"
import React, { useEffect, useState } from "react"
import StatsItem from "./StatsItem"
import { CreateVersionModal } from "../shared/modals"
import CreateProcessModal from "../shared/modals/processes/CreateProcessModal"
import { useStore } from "../../stores"
import { observer } from "mobx-react"

import moment from "moment"

export const rearrangeName = (string) => {
	// convert name string into array, split by spaces
	string = string.split(" ")

	// get first name
	const firstPart = string[0]

	// reconnect the rest of the name by spaces
	// trim is just to remove trailing spaces
	const restOfString = string.slice(1).join(" ").trim()

	// return rearranged name, separated by comma
	return `${restOfString} ${firstPart}`
}

const OTAScreenHeader = observer(() => {
	const { dataStore } = useStore()

	const [isCreateVersionModalOpen, setIsCreateVersionModalOpen] = useState()
	const handleOpenCreateVersionModalOpen = () => setIsCreateVersionModalOpen(true)
	const handleCloseCreateVersionModalOpen = () => setIsCreateVersionModalOpen(false)

	const [isProcessModalOpen, setIsProcessModalOpen] = useState()
	const handleOpenProcessModalOpen = () => setIsProcessModalOpen(true)

	const handleCloseProcessModalOpen = async (error) => {
		setIsProcessModalOpen(false)
		await dataStore.getProcesses()
	}

	return (
		<Flex gap="middle" justify="end" align="flex-end" wrap="wrap" style={{ width: "100%", marginBottom: "24px" }}>
			<Flex gap="middle" justify="flex-end" align="flex-end" style={{ maxWidth: "39%", flexWrap: "wrap" }}>
				<Button onClick={handleOpenCreateVersionModalOpen} type="primary">
					Create New Version
				</Button>
				<Button onClick={handleOpenProcessModalOpen} type="primary">
					Create New Process
				</Button>
			</Flex>

			{isCreateVersionModalOpen && (
				<CreateVersionModal isOpen={isCreateVersionModalOpen} onCancel={handleCloseCreateVersionModalOpen} />
			)}

			{isProcessModalOpen && <CreateProcessModal isOpen={isProcessModalOpen} onCancel={handleCloseProcessModalOpen} />}
		</Flex>
	)
})

export default OTAScreenHeader
