import { Layout, DatePicker, Space, Table, Card, Button, Col, Row, Typography, Result } from "antd"
import dayjs from "dayjs"
import moment from "moment"
import { presets } from "../utils/dateRangePreset"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { API } from "../App"
import { CSVLink } from "react-csv"
import { DownloadOutlined } from "@ant-design/icons"
import { AvgFlavorChart, FlavorOppsChart, ScreenLayout, Top5Widget, HierarchySelector } from "../components"
import { useLocation } from "react-router-dom"
import { calculateArrayAverage, calculateWeightArrayAverage } from "../utils/calculateArrayAverage"
import { getDivideResult } from "../utils/calculations"
import CustomTableColumn from "../components/dashboard/CustomTableColumn"
import { sumCondensedLog } from "../utils/sumCondensedLog"

const _ = require("lodash")

const { Text } = Typography
const { Header } = Layout
const { RangePicker } = DatePicker

const isLeapYear = (year) => {
	return year % 4 === 0
}

const getEarliestCondensedLogDate = (condensedLog) => {
	if (!condensedLog) return condensedLog
	let earliestDate = null

	for (const dateStr in condensedLog) {
		const currentDate = new Date(dateStr)

		if (currentDate.getTime() < new Date("2015-01-01").getTime()) {
			continue
		}

		if (!earliestDate || currentDate < earliestDate) {
			earliestDate = currentDate
		}
	}

	return earliestDate
}

const getFormattedDate = (dateString) => {
	if (!dateString || typeof dateString !== "string") return ""
	return dateString.replace(/ /g, "+")
}

const Dashboard = (props) => {
	const { search, pathname } = useLocation()
	const s = new URLSearchParams(search)

	let { dateRangeEnd, dateRangeStart, customerName, hierarchyLevel2, hierarchyLevel3, hierarchyLevel4 } =
		Object.fromEntries(s)
	const navigate = useNavigate()
	const reloadPage = () => {
		window.location.reload() // Reload the page
	}

	const initialStartDateRange = getFormattedDate(dateRangeStart)
	const initialEndDateRange = getFormattedDate(dateRangeEnd)

	if (!dateRangeStart) dateRangeStart = dayjs().subtract(1, "week")
	if (!dateRangeEnd) dateRangeEnd = dayjs()

	const initialDateRange = [dayjs(initialStartDateRange), dayjs(initialEndDateRange)]

	const initialHierarchyArr = [customerName, hierarchyLevel2, hierarchyLevel3, hierarchyLevel4]

	let initialHierarchy = {}
	let initialHierarchyLevel = 0
	for (const hierarchyLevel of initialHierarchyArr) {
		if (!hierarchyLevel) {
			break
		}
		initialHierarchyLevel++
		initialHierarchy[`hierarchyLevel${initialHierarchyLevel}`] = hierarchyLevel
	}

	// Global Chart Variables
	let chartDataDaysObj = {}
	let chartAllDrugs = {}
	let chartAllFlavors = {}

	const [filteredStores, setFilteredStores] = useState([])

	const [filteredDateRange, setFilteredDateRange] = useState(initialDateRange)
	const [storeCount, setStoreCount] = useState(0)
	const [opportunities, setOpportunities] = useState(0)
	const [opportunitiesAvg, setOpportunitiesAvg] = useState(0)
	const [flavorings, setFlavorings] = useState(0)
	const [flavoringsAvg, setFlavoringsAvg] = useState(0)
	const [performance, setPerformance] = useState(0)
	const [tableData, setTableData] = useState([])
	const [chartData, setChartData] = useState([])
	const [csvData, setCSVData] = useState([])
	const [topDrugs, setTopDrugs] = useState([])
	const [topDrugPercent, setTopDrugPercent] = useState(0)
	const [topFlavors, setTopFlavors] = useState([])
	const [topFlavorPercent, setTopFlavorPercent] = useState(0)
	const [performanceData, setPerformanceData] = useState([])

	const [loading, setLoading] = useState(true)

	const [hierarchyLevel, setHierarchyLevel] = useState(initialHierarchyLevel)
	const [tableDataHierarchy, setTableDataHierarchy] = useState([])
	const [hierarchyName, setHierarchyName] = useState("")
	const [storeData, setStoreData] = useState()
	const [searchState, setSearchState] = useState(search)
	const [hierarchyStoreData, setHierarchyStoreData] = useState([])

	// Get the stores for the selected customer

	useEffect(() => {
		// Fetch data if path search changes
		if (searchState === search) return

		setSearchState(search)
		reloadPage()
	}, [search])

	// Generate hierarchyStoreData
	useEffect(() => {
		if (!storeData) return

		const hierarchy = {}

		// Iterate through each store
		storeData.forEach((store) => {
			let currentLevel = hierarchy

			// Iterate through each hierarchy level and create nested objects
			;["hierarchyLevel1", "hierarchyLevel2", "hierarchyLevel3", "hierarchyLevel4", "hierarchyLevel5"].forEach(
				(levelKey) => {
					const levelValue = store[levelKey]
					if (levelValue) {
						if (!currentLevel[levelValue]) {
							currentLevel[levelValue] = {}
						}
						currentLevel = currentLevel[levelValue]
					}
				},
			)

			// Add the store to the final hierarchy level
			if (!currentLevel.stores) {
				currentLevel.stores = []
			}
			currentLevel.stores.push({ ...store, storeLevel: "hierarchyLevelStore" })
		})

		setHierarchyStoreData(hierarchy)
	}, [storeData])

	useEffect(() => {
		if (customerName) {
			const dashboardWhere = {}
			let counter = 0

			for (const hierarchyLevel of initialHierarchyArr) {
				if (!hierarchyLevel) {
					break
				}
				counter++
				dashboardWhere[`hierarchyLevel${counter}`] = hierarchyLevel
			}

			API.streamStoresData({ where: dashboardWhere }).then((res) => {
				setStoreData(res.data)

				API.streamFilteredSnapshotsData({ where: dashboardWhere }).then((res) => {
					setFilteredStores(res.data)
					if (!res?.data?.length) {
						setLoading(false)
					}
				})
			})
		}
	}, [])

	useEffect(() => {
		// Calculate performance data (to display in the widget on the left)
		if (storeCount > 0) {
			if (flavorings > 0 || opportunities > 0) {
				let data = [
					{ label: "Stores", value: storeCount },
					{ label: "Opportunities", value: opportunities },
					{ label: "Opps/Day/Store", value: opportunitiesAvg },
					{ label: "Flavorings", value: flavorings },
					{ label: "Flavorings/Day/Store", value: flavoringsAvg },
				]
				setPerformanceData(data)
			}
		}
	}, [storeCount, flavorings, opportunities, filteredDateRange, opportunitiesAvg, flavoringsAvg])

	// Define columns for log table
	const columns = [
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			render: (title, record) => {
				if (!record.children) {
					return (
						<Button
							type="link"
							onClick={() => {
								let selectedStore = storeData?.find((d) => d.store_name === record.title)
								navigate(
									`/store/?storeID=${
										selectedStore.id || selectedStore._id
									}&dateRangeStart=${filteredDateRange[0].format(
										"MM/DD/YYYY",
									)}&dateRangeEnd=${filteredDateRange[1].format("MM/DD/YYYY")}`,
								)
							}}
						>
							{title}
						</Button>
					)
				} else
					return (
						<Button
							type="link"
							onClick={() => {
								const tableElHierarchyArray = record?.key.split("|")

								let itemQueryStringResult = `customerName=${tableElHierarchyArray[0]}`

								for (let index = 0; index <= tableElHierarchyArray.length; index++) {
									if (index === 0 || !tableElHierarchyArray[index]) {
										continue
									}
									itemQueryStringResult += `&hierarchyLevel${index + 1}=${tableElHierarchyArray[index]}`
								}

								navigate(
									`/dashboard?${itemQueryStringResult}&dateRangeStart=${filteredDateRange[0].format()}&dateRangeEnd=${filteredDateRange[1].format()}`,
								)
							}}
						>
							{title}
						</Button>
					)
			},
		},
		{
			title: "Stores",
			dataIndex: "stores",
			key: "stores",
			render: (text) => <CustomTableColumn text={text} digitsQuantity={5} />,
		},
		{
			title: "Recon",
			dataIndex: "recon",
			key: "recon",
			sorter: (a, b) => a.recon - b.recon,
			render: (text) => <CustomTableColumn text={text.toLocaleString()} digitsQuantity={8} />,
		},
		{
			title: "Recon/Flavor",
			dataIndex: "reconFlavor",
			key: "reconFlavor",
			sorter: (a, b) => a.reconFlavor - b.reconFlavor,
			render: (text) => <CustomTableColumn text={text.toLocaleString()} digitsQuantity={8} />,
		},
		{
			title: "Flavor",
			dataIndex: "flavor",
			key: "flavor",
			sorter: (a, b) => a.flavor - b.flavor,
			render: (text) => <CustomTableColumn text={text.toLocaleString()} digitsQuantity={5} />,
		},
		{
			title: "Opps",
			dataIndex: "opportunities",
			key: "opportunities",
			sorter: (a, b) => a.opportunities - b.opportunities,
			render: (text) => <CustomTableColumn text={text.toLocaleString()} digitsQuantity={8} />,
		},
		{
			title: "Opps/Day/Store",
			dataIndex: "oppsPerStore",
			key: "oppsPerStore",
			sorter: (a, b) => a.oppsPerStore - b.oppsPerStore,
			render: (text) => <CustomTableColumn text={text.toLocaleString()} digitsQuantity={5} />,
		},
		{
			title: "Flavorings",
			dataIndex: "flavorings",
			key: "flavorings",
			sorter: (a, b) => a.flavorings - b.flavorings,

			render: (text) => <CustomTableColumn text={text.toLocaleString()} digitsQuantity={8} />,
		},
		{
			title: "Flavorings/Day/Store",
			dataIndex: "flavoringsPerStore",
			key: "flavoringsPerStore",
			sorter: (a, b) => a.flavoringsPerStore - b.flavoringsPerStore,
			render: (text) => <CustomTableColumn text={text.toLocaleString()} digitsQuantity={5} />,
		},
		{
			title: "Flavoring %",
			dataIndex: "performance",
			key: "performance",
			sorter: (a, b) => a.performance - b.performance,
			render: (text) => <CustomTableColumn text={text.toLocaleString()} digitsQuantity={5} />,
		},
		{
			title: "Connected On",
			dataIndex: "connectedOn",
			key: "connectedOn",
			sorter: (a, b) => a.connectedOn - b.connectedOn,
			render: (text, data) => text && dayjs(text).format("MM/DD/YYYY"),
		},
	]

	// Generates data structure to display in the charts
	const getTotalStatistic = (storeHierarchyObjects) => {
		try {
			// Global Variables
			let opportunityCountTotal = 0
			let flavoringCountTotal = 0
			// Table Global Variables
			let tableData = []
			let tableCsvRowData = []
			// Store Level Variables
			let isObjectsWasModifided = false

			let rangeSpan = getRangeSpan()

			// Get Statistic Data With Recursion
			storeHierarchyObjects.forEach((hierarchyObj, idx) => {
				if (hierarchyObj && (hierarchyObj?.storeLevel || hierarchyObj?.level === "hierarchyLevelStore")) {
					if (hierarchyObj?.level === "hierarchyLevelStore" && !hierarchyObj?.storeLevel) {
						if (!isObjectsWasModifided) {
							storeHierarchyObjects = storeHierarchyObjects.map((el) =>
								storeData.find((storeEl) => storeEl.id === el.storeId),
							)
							isObjectsWasModifided = true
						}
						hierarchyObj = storeHierarchyObjects[idx]
					}

					try {
						let opportunityCount = 0
						let flavoringCount = 0
						let reconCount = 0
						let flavorCount = 0
						let reconFlavorCount = 0
						let daysCount = 0

						const addRange = (m) => {
							if (dayjs(filteredDateRange[1]).month() + 1 != m.month() + 1) {
								if (m.endOf("month").date === m.date()) {
									return m.startOf("month").add(1, "month")
								}
							}

							return m.add(1, rangeSpan)
						}

						for (
							let m = dayjs(filteredDateRange[0]);
							m.isSame(filteredDateRange[1]) || m.isBefore(filteredDateRange[1]);
							m = addRange(m)
						) {
							// if (dayjs(filteredDateRange[1]).month() + 1 === m.month() + 1) {
							// 	m = dayjs(filteredDateRange[1]).startOf("month").date()
							// }

							let dayKey = m.format("MM/DD/YYYY")
							let daysAmount = 1

							if (rangeSpan === "month") {
								dayKey = m.format("MM/YYYY")

								daysAmount = m.daysInMonth()

								if (dayjs(filteredDateRange[0]).month() + 1 === m.month() + 1) {
									daysAmount = -m.diff(m.endOf("month"), "days") + 1
								}

								if (dayjs(filteredDateRange[1]).month() + 1 === m.month() + 1) {
									daysAmount = m.diff(m.startOf("month"), "days") + 1
								}
							}

							if (rangeSpan === "year") {
								dayKey = m.format("YYYY")
								daysAmount = isLeapYear(dayKey) ? 366 : 365
							}

							let dayObj = hierarchyObj["condensed_log"][rangeSpan][dayKey]

							if (rangeSpan === "month") {
								const currentMonthsDays = m.daysInMonth()

								if (daysAmount < currentMonthsDays) {
									let slicesCondensedLog = {}

									let startIteration = 1
									let iterationsMax = 1

									if (dayjs(filteredDateRange[0]).month() + 1 === m.month() + 1) {
										startIteration = new Date(filteredDateRange[0]).getDate()
										iterationsMax = currentMonthsDays
									}

									if (dayjs(filteredDateRange[1]).month() + 1 === m.month() + 1) {
										startIteration = 1
										iterationsMax = new Date(filteredDateRange[1]).getDate()
									}

									let isFirstSavedIteration = false

									for (let i = startIteration; i <= iterationsMax; i++) {
										const [month, year] = dayKey.split("/")

										const day = i < 10 ? `0${i}` : i

										const date = `${month}/${day}/${year}`

										const log = hierarchyObj["condensed_log"]["day"][date]

										if (!log) continue

										if (!isFirstSavedIteration) {
											slicesCondensedLog = log

											isFirstSavedIteration = true
										} else {
											slicesCondensedLog = sumCondensedLog(slicesCondensedLog, log)
										}
									}

									dayObj = slicesCondensedLog
								}
							}

							if (_.isEmpty(dayObj)) {
								continue
							}

							const opportunitiesDayStore = dayObj.opportunities || 0
							const flavoringsDayStore = dayObj.flavorings || 0
							const flavorDayStore = dayObj.flavor_dispense || 0
							const reconFlavorDayStore = dayObj.recons_and_flavor_dispense || 0
							const reconDayStore = dayObj.recons_dispense || 0

							daysCount += daysAmount
							opportunityCount += opportunitiesDayStore

							flavoringCount += flavoringsDayStore
							reconCount += reconDayStore
							flavorCount += flavorDayStore
							reconFlavorCount += reconFlavorDayStore

							for (const [key, value] of Object.entries(dayObj.drugs)) {
								if (chartAllDrugs[key]) chartAllDrugs[key] += value
								else chartAllDrugs[key] = value
							}

							for (const [key, value] of Object.entries(dayObj.flavors)) {
								if (chartAllFlavors[key]) chartAllFlavors[key] += value
								else chartAllFlavors[key] = value
							}

							try {
								if (chartDataDaysObj[dayKey]) {
									const { recon, stores, flavor, reconFlavor, opportunities, flavorings } = chartDataDaysObj[dayKey]
									const updatedStores = stores + 1
									const updatedOpportunities = opportunities + opportunitiesDayStore
									const updatedFlavorings = flavorings + flavoringsDayStore

									chartDataDaysObj[dayKey] = {
										...chartDataDaysObj[dayKey],
										recon: recon + reconDayStore,
										flavor: flavor + flavorDayStore,
										reconFlavor: reconFlavor + reconFlavorDayStore,
										opportunities: updatedOpportunities,
										flavorings: updatedFlavorings,
										oppsPerStore: getDivideResult(
											getDivideResult(updatedOpportunities, updatedStores),
											daysAmount,
										).toFixed(1),
										flavoringsPerStore: getDivideResult(
											getDivideResult(updatedFlavorings, updatedStores),
											daysAmount,
										).toFixed(1),
										performance: +(getDivideResult(updatedFlavorings, updatedOpportunities) * 100).toFixed(2),
										stores: updatedStores,
									}
								} else {
									let chartDataRow = {
										key: dayKey,
										date: dayKey,
										recon: reconDayStore,
										flavor: flavorDayStore,
										reconFlavor: reconFlavorDayStore,
										opportunities: opportunitiesDayStore,
										flavorings: flavoringsDayStore,
										oppsPerStore: getDivideResult(opportunitiesDayStore, daysAmount).toFixed(1),
										flavoringsPerStore: getDivideResult(flavoringsDayStore, daysAmount).toFixed(1),
										performance: +(getDivideResult(flavoringsDayStore, opportunitiesDayStore) * 100).toFixed(2),
										stores: 1,
									}

									chartDataDaysObj[dayKey] = chartDataRow
								}
							} catch (error) {
								console.error(error)
							}

							opportunityCountTotal += opportunityCount
							flavoringCountTotal += flavoringCount
						}

						if (daysCount) {
							let performance = (getDivideResult(flavoringCount, opportunityCount) * 100).toFixed(2) + "%"

							let _stores = 1

							const storeConnectedOn = hierarchyObj?.connectedOn

							let storeConnectedOnDate = storeConnectedOn ? dayjs(storeConnectedOn).format("MM/DD/YYYY") : null

							if (!storeConnectedOnDate) {
								const earliestLogDate = getEarliestCondensedLogDate(hierarchyObj?.condensed_log?.day)

								storeConnectedOnDate = earliestLogDate ? dayjs(earliestLogDate).format("MM/DD/YYYY") : null
							}

							let dataRow = {
								key: hierarchyObj.id,
								title: hierarchyObj.store_name || "Store",
								_stores: _stores,
								recon: reconCount,
								flavor: flavorCount,
								reconFlavor: reconFlavorCount,
								opportunities: opportunityCount,
								flavorings: flavoringCount,
								oppsPerStore: getDivideResult(opportunityCount, daysCount).toFixed(1),
								flavoringsPerStore: getDivideResult(flavoringCount, daysCount).toFixed(1),
								oppsPerStoreActual: getDivideResult(opportunityCount, daysCount),
								flavoringsPerStoreActual: getDivideResult(flavoringCount, daysCount),
								daysCount,
								performance,
								connectedOn: storeConnectedOnDate,
							}

							let csvDataRow = { ...dataRow }
							delete csvDataRow.key

							tableData.push(dataRow)
							tableCsvRowData.push(csvDataRow)
						}
					} catch (err) {
						console.log(err)
						return
					}
				} else {
					try {
						let children = []
						let stores = 0
						let totalStoresOpportunities = []
						let isStoreLevel = false
						let totalStoresFlavorings = []
						let reconCount = 0
						let flavorCount = 0
						let reconFlavorCount = 0
						let opportunityCount = 0
						let flavoringCount = 0
						let storesWeight = 0

						if (hierarchyObj.children) {
							// Send list of stores to generateTableData to get data for children
							if (
								hierarchyObj.level === "hierarchyLevel4" ||
								(hierarchyObj?.children && !hierarchyObj?.children[0].children)
							) {
								const hierarchyLevelsPath = hierarchyObj.hierarchy?.split("|")

								if (hierarchyLevelsPath.length) {
									let currentData = hierarchyStoreData
									for (const key of hierarchyLevelsPath) {
										if (!key) continue
										currentData = currentData[key]
										if (!currentData) {
											currentData = null
											break
										}
									}
									if (currentData) {
										const storesWithData = currentData?.stores
										children = getTotalStatistic(storesWithData).tableData
									}
								}
							} else {
								children = getTotalStatistic(hierarchyObj.children).tableData
							}
						}

						for (const storeData of children) {
							if (storeData._stores) {
								isStoreLevel = true
								stores += storeData._stores
								totalStoresOpportunities.push(storeData.oppsPerStoreActual * storeData.daysCount)
								totalStoresFlavorings.push(storeData.flavoringsPerStoreActual * storeData.daysCount)
								storesWeight += storeData.daysCount
							} else {
								isStoreLevel = false
								stores += storeData.stores
								totalStoresOpportunities.push(storeData.oppsPerStoreActual * storeData.stores)
								totalStoresFlavorings.push(storeData.flavoringsPerStoreActual * storeData.stores)
							}
							reconCount += storeData.recon
							flavorCount += storeData.flavor
							reconFlavorCount += storeData.reconFlavor
							opportunityCount += storeData.opportunities
							flavoringCount += storeData.flavorings
						}

						if (!stores) {
							return
						}

						let performance = (getDivideResult(flavoringCount, opportunityCount) * 100 || 0).toFixed(2) + "%"
						const oppAvg = isStoreLevel
							? calculateWeightArrayAverage(totalStoresOpportunities, storesWeight)
							: calculateWeightArrayAverage(totalStoresOpportunities, stores)
						const flavAvg = isStoreLevel
							? calculateWeightArrayAverage(totalStoresFlavorings, storesWeight)
							: calculateWeightArrayAverage(totalStoresFlavorings, stores)

						let dataRow = {
							key: `${hierarchyObj.hierarchy}`,
							title:
								hierarchyObj.value ||
								(hierarchyObj?.hierarchy ? hierarchyObj?.hierarchy?.replaceAll("|", "") : "Hierarchy Level"),
							stores,
							recon: reconCount,
							flavor: flavorCount,
							reconFlavor: reconFlavorCount,
							opportunities: opportunityCount,
							flavorings: flavoringCount,
							oppsPerStore: oppAvg.toFixed(1),
							flavoringsPerStore: flavAvg.toFixed(1),
							oppsPerStoreActual: oppAvg,
							flavoringsPerStoreActual: flavAvg,
							performance,
							level: hierarchyObj.level,
						}
						if (hierarchyObj.children) {
							dataRow.children = children
						}

						let csvDataRow = { ...dataRow }
						delete csvDataRow.key

						tableData.push(dataRow)
						tableCsvRowData.push(csvDataRow)
					} catch (error) {
						console.error(error)
					}
				}
			})

			return { tableData, tableCsvRowData }
		} catch (error) {
			console.error(error)
			return { tableData: [], tableCsvRowData: [] }
		}
	}

	// Monitor filter changes to regenerate data
	useEffect(() => {
		if (
			!filteredStores ||
			!filteredDateRange ||
			hierarchyLevel === 0 ||
			filteredStores.length === 0 ||
			tableDataHierarchy.length === 0
		) {
			return
		}
		setLoading(true)
		let geneatedTotalStatistic = getTotalStatistic(tableDataHierarchy)
		let generatedTableData = {
			data: geneatedTotalStatistic?.tableData,
			csvRowData: geneatedTotalStatistic?.tableCsvRowData,
		}

		let generatedAverageData = {
			data: [],
			csvRowData: [],
			flavoringCountTotal: 0,
			opportunityCountTotal: 0,
			flavoringsByDaysPerStore: [],
			opportunitysByDaysPerStore: [],
		}

		// Add Chart Data
		for (const dayRow in chartDataDaysObj) {
			if (Object.hasOwnProperty.call(chartDataDaysObj, dayRow)) {
				generatedAverageData.data.push(chartDataDaysObj[dayRow])
			}
		}

		// Sorting Chart Array By Date
		generatedAverageData.data = generatedAverageData.data.sort((a, b) => {
			const splitDateA = a.date.split("/")
			const splitDateB = b.date.split("/")

			if (splitDateA.length === 1) {
				return a.date - b.date
			}

			if (splitDateA.length === 2) {
				const dateA = splitDateA?.reverse()?.join("")
				const dateB = splitDateB?.reverse()?.join("")
				return dateA - dateB
			}

			if (splitDateA.length === 3) {
				const dateA = splitDateA[2] + splitDateA[0] + splitDateA[1]
				const dateB = splitDateB[2] + splitDateB[0] + splitDateB[1]
				return dateA - dateB
			}

			const dateA = a.date
			const dateB = b.date
			if (dateA < dateB) return -1
			if (dateA > dateB) return 1
			return 0
		})

		// Get Data For Chart Performance Averages
		if (generatedTableData?.data && generatedTableData?.data.length) {
			for (const topHierarchyElement of generatedTableData.data) {
				generatedAverageData.opportunityCountTotal += topHierarchyElement?.opportunities || 0
				generatedAverageData.flavoringCountTotal += topHierarchyElement?.flavorings || 0

				topHierarchyElement?.oppsPerStore
					? generatedAverageData.opportunitysByDaysPerStore.push(topHierarchyElement?.oppsPerStore)
					: undefined
				topHierarchyElement?.flavoringsPerStore
					? generatedAverageData.flavoringsByDaysPerStore.push(topHierarchyElement?.flavoringsPerStore)
					: undefined
			}
		}

		setOpportunitiesAvg(calculateArrayAverage(generatedAverageData.opportunitysByDaysPerStore).toFixed(1))
		setFlavoringsAvg(calculateArrayAverage(generatedAverageData.flavoringsByDaysPerStore).toFixed(1))

		let drugs = Object.entries(chartAllDrugs)
			.map(([key, value]) => ({ label: key, value }))
			.sort((a, b) => b.value - a.value)
			.slice(0, 5)
		let flavors = Object.entries(chartAllFlavors)
			.map(([key, value]) => ({ label: key, value }))
			.sort((a, b) => b.value - a.value)
			.slice(0, 5)
		setTopDrugs(drugs)
		setTopFlavors(flavors)

		if (drugs.length > 0) {
			setTopDrugPercent(parseInt(getDivideResult(drugs[0].value, generatedAverageData?.opportunityCountTotal) * 100))
		}
		if (flavors.length > 0) {
			setTopFlavorPercent(parseInt(getDivideResult(flavors[0].value, generatedAverageData?.flavoringCountTotal) * 100))
		}

		let performanceTotal =
			generatedAverageData.opportunityCountTotal > 0 || generatedAverageData.flavoringCountTotal > 0
				? (
						getDivideResult(generatedAverageData?.flavoringCountTotal, generatedAverageData?.opportunityCountTotal) *
						100
					).toFixed(2)
				: 0

		let generatedTableDataStoreCount = generatedTableData?.data?.reduce((total, item) => {
			return total + item.stores
		}, 0)

		const sortChildren = (data) => {
			if (data.children) {
				data.children.sort((a, b) => {
					const regex = /\d+/

					const numA = parseInt(a.title.match(regex))
					const numB = parseInt(b.title.match(regex))

					if (isNaN(numA) && isNaN(numB)) {
						return 0
					} else if (isNaN(numA)) {
						return 1
					} else if (isNaN(numB)) {
						return -1
					} else {
						return numA - numB
					}
				})
				if (data.children.length > 0) {
					data.children.forEach((child) => {
						sortChildren(child)
					})
				}
			}
		}

		generatedTableData.data.forEach((item) => {
			sortChildren(item)
		})

		const sortedData = generatedTableData.data.sort(({ title: valueA }, { title: valueB }) => {
			const regex = /\d+/

			const numA = parseInt(valueA.match(regex))
			const numB = parseInt(valueB.match(regex))

			if (isNaN(numA) && isNaN(numB)) {
				return 0
			} else if (isNaN(numA)) {
				return 1
			} else if (isNaN(numB)) {
				return -1
			} else {
				return numA - numB
			}
		})

		setStoreCount(generatedTableDataStoreCount)
		setOpportunities(generatedAverageData.opportunityCountTotal)
		setFlavorings(generatedAverageData.flavoringCountTotal)
		setPerformance(performanceTotal)
		setChartData(generatedAverageData.data)
		setTableData(sortedData)
		setCSVData(generatedTableData.csvRowData)

		setLoading(false)
	}, [filteredStores, filteredDateRange, tableDataHierarchy])

	const getRangeSpan = () => {
		let rangeSpan = "day"
		const rangeDif = dayjs(filteredDateRange[1]).diff(filteredDateRange[0], "months", true)
		if (rangeDif > 12) {
			rangeSpan = "year"
		} else if (rangeDif > 2) {
			rangeSpan = "month"
		}
		return rangeSpan
	}
	// Define headers for CSV download
	const headers = [
		{ label: "Title", key: "title" },
		{ label: "Recon", key: "recon" },
		{ label: "Flavor", key: "flavor" },
		{ label: "Recon/Flavor", key: "reconFlavor" },
		{ label: "Opps", key: "opportunities" },
		{ label: "Opps/Store", key: "oppsPerStore" },
		{ label: "Flavorings", key: "flavorings" },
		{ label: "Flavorings/Store", key: "flavoringsPerStore" },
		{ label: "Flavoring %", key: "performance" },
	]

	return (
		<ScreenLayout showSearchBar>
			<Header
				style={{
					background: "#DCDCDC",
					height: 64,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Space direction="horizontal">
					<HierarchySelector
						hierarchyLevel={hierarchyLevel}
						hierarchyLevels={initialHierarchy}
						tableDataHierarchy={tableDataHierarchy}
						filteredStores={filteredStores}
						filteredDateRange={filteredDateRange}
						onSetHierarchyLevel={setHierarchyLevel}
						onSetHierarchyName={setHierarchyName}
						onSetTableDataHierarchy={setTableDataHierarchy}
						onSetFilteredStores={setFilteredStores}
						storeData={storeData}
					/>

					<RangePicker
						presets={presets}
						value={filteredDateRange}
						format="MM/DD/YYYY"
						onChange={(dateRange) => {
							const searchString = "&dateRangeStart"
							const resultUrl = search.split(searchString)[0]

							navigate(
								pathname +
									resultUrl +
									`&dateRangeStart=${dateRange[0].format("MM/DD/YYYY")}&dateRangeEnd=${dateRange[1].format(
										"MM/DD/YYYY",
									)}`,
							)
						}}
					/>
				</Space>
			</Header>
			<Layout style={{ margin: 20 }}>
				{!loading && (
					<Row gutter={[24, 24]} wrap={false} justify="end">
						<Col flex="350px">
							<Top5Widget
								title="Performance Averages"
								topPercent={parseInt(performance)}
								topLabel="Flavoring %"
								data={performanceData}
								color="#F5C05B"
							/>

							<Top5Widget
								title="Top 5 Drugs"
								topPercent={topDrugPercent}
								data={topDrugs.slice(0, 5)}
								style={{ marginTop: 20 }}
								color="#679FF6"
								extraText="Of All Scripts"
							/>

							<Top5Widget
								title="Top 5 Flavors"
								topPercent={topFlavorPercent}
								data={topFlavors.slice(0, 5)}
								style={{ marginTop: 20, marginBottom: 20 }}
								color="#E5883B"
								extraText="Of All Flavors"
							/>

							{filteredStores && filteredDateRange && (
								<CSVLink
									data={csvData}
									headers={headers}
									filename={`${hierarchyName}-${dayjs().format("MM-DD-YYYY")}.csv`}
								>
									<Button type="primary" icon={<DownloadOutlined />} style={{ float: "right" }}>
										Download CSV
									</Button>
								</CSVLink>
							)}
						</Col>
						<Col flex="auto">
							{filteredStores && filteredDateRange && (
								<>
									<Card title="Opportunities and Flavorings" size="small">
										<div style={{ float: "left" }}>
											<Text style={{ fontSize: 24 }} strong>
												{opportunities?.toLocaleString() || [].toLocaleString()}
											</Text>
											<br />
											<div
												style={{
													backgroundColor: "#3F8DA9",
													marginTop: 7,
													marginRight: 3,
													width: 10,
													height: 10,
													borderRadius: 5,
													float: "left",
												}}
											/>
											<Text style={{ fontSize: 12 }}>Opportunities</Text>
										</div>
										<div style={{ float: "left", marginLeft: 30 }}>
											<Text style={{ fontSize: 24 }} strong>
												{flavorings?.toLocaleString() || [].toLocaleString()}
											</Text>
											<br />

											<div
												style={{
													backgroundColor: "#F5C05B",
													marginTop: 7,
													marginRight: 3,
													width: 10,
													height: 10,
													borderRadius: 5,
													float: "left",
												}}
											/>
											<Text style={{ fontSize: 12 }}>Flavorings</Text>
										</div>
										<div style={{ clear: "both", marginBottom: 10 }}></div>
										<FlavorOppsChart
											data={chartData}
											rangeSpan={getRangeSpan()}
											start={filteredDateRange[0]}
											end={filteredDateRange[1]}
										/>
									</Card>
									<Card title="Average Flavoring" size="small" style={{ marginTop: 20, marginBottom: 20 }}>
										<Text style={{ fontSize: 24, paddingBottom: 10 }} strong>
											{performance}%
										</Text>
										<AvgFlavorChart
											data={chartData}
											rangeSpan={getRangeSpan()}
											start={filteredDateRange[0]}
											end={filteredDateRange[1]}
										/>
									</Card>
								</>
							)}

							{filteredStores && filteredDateRange && (
								<div style={{ backgroundColor: "#fff" }}>
									<Table
										scroll={{ x: 400 }}
										size="small"
										columns={columns}
										dataSource={tableData}
										pagination={{ pageSize: 15 }}
									/>
								</div>
							)}
						</Col>
					</Row>
				)}
				{loading && <Result title="Processing request, please be patient." />}
			</Layout>
		</ScreenLayout>
	)
}

Dashboard.propTypes = {
	location: PropTypes.object,
}

export default Dashboard
