import { Layout } from "antd"
import PropTypes from "prop-types"
import { observer } from "mobx-react"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useStore } from "../../stores"
import { CodeOutlined, InfoCircleOutlined, UserOutlined } from "@ant-design/icons"
import { HeaderButton, UserMenu, UserRoleTag, SearchBar } from "../"
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons"
import { AboutModal } from "../shared/modals"

const { Content, Footer, Header } = Layout

export const ScreenLayout = observer(({ children, showSearchBar, hideCustomerSearch }) => {
	const { authStore } = useStore()
	const navigate = useNavigate()
	const user = authStore.user
	const onLogoutClick = () => {
		authStore.logout()
		navigate("/login")
	}

	const onUserManagementClick = () => {
		navigate("/user-management")
	}

	const onOTAManagementClick = () => {
		navigate("/ota-management")
	}

	// About popup modal add
	const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)

	const showAboutPopup = () => {
		setIsAboutModalOpen(true)
	}

	const handleCloseAboutPopup = () => {
		setIsAboutModalOpen(false)
	}

	const LogOutItem = {
		label: (
			<HeaderButton onClick={onLogoutClick} text={"Log Out"} icon={<LogoutOutlined style={{ marginRight: 10 }} />} />
		),
		key: "logout",
	}

	const UserManagementItem = {
		label: (
			<HeaderButton
				onClick={onUserManagementClick}
				text={"User Management"}
				icon={<SettingOutlined style={{ marginRight: 10 }} />}
			/>
		),
		key: "user-management",
	}

	const LabelItem = {
		label: (
			<HeaderButton onClick={showAboutPopup} text={"About"} icon={<InfoCircleOutlined style={{ marginRight: 10 }} />} />
		),
		key: "about",
	}

	const OtaItem = {
		label: (
			<HeaderButton
				onClick={onOTAManagementClick}
				text={"OTA Management"}
				icon={<CodeOutlined style={{ marginRight: 10 }} />}
			/>
		),
		key: "ota-management",
	}

	const items = []

	if (authStore.isAdmin && authStore.authenticated) {
		items.push(OtaItem)
		items.push(UserManagementItem)
		items.push(LabelItem)
	}

	if (authStore.authenticated) {
		items.push(LogOutItem)
	}

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Header
				style={{
					maxHeight: 75,
					background: "white",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<img onClick={() => navigate("/")} src={require("../../logo.png")} height={50} alt="Logo" />
				{showSearchBar && <SearchBar hideRangePicker hideCustomerSearch={hideCustomerSearch} />}
				{user && (
					<div style={{ display: "flex", alignItems: "center", cursor: "default" }}>
						{/* {
							<div>
								<span style={{ fontSize: 10, marginRight: 10, color: 'rgb(0 0 0 / 51%)' }}>{'v 1.4 [11/09/2023]'}</span>
							</div>
						} */}
						<div>
							{user.roles.map(({ name }, index) => (
								<UserRoleTag key={index} role={name} />
							))}
						</div>

						<div style={{ cursor: "pointer" }}>
							<UserOutlined style={{ marginRight: 5 }} />
							<UserMenu userName={user.name} items={items} />
						</div>
					</div>
				)}
			</Header>
			<Layout>
				<Content>{children}</Content>
				<Footer style={{ textAlign: "center" }}></Footer>
			</Layout>

			<AboutModal isOpen={isAboutModalOpen} onSuccess={handleCloseAboutPopup} onCancel={handleCloseAboutPopup} />
		</Layout>
	)
})

ScreenLayout.propTypes = {
	children: PropTypes.node.isRequired,
	showSearchBar: PropTypes.bool,
	hideCustomerSearch: PropTypes.bool,
}

export default ScreenLayout
