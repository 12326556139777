import React from "react"
import { RouterProvider } from "react-router-dom"
import router from "./router"
import { StoreContext, store } from "./stores"
import RestAPI from "./utils/API"
import { ConfigProvider } from "antd"

function App() {
	return (
		<ConfigProvider
			theme={{
				token: {
					colorBorder: "#c9c9c9",
					colorBorderBg: "#c9c9c9",
					colorBorderSecondary: "#c9c9c9",
				},
			}}
		>
			<StoreContext.Provider value={store}>
				<RouterProvider router={router} />
			</StoreContext.Provider>
		</ConfigProvider>
	)
}

export default App

export const API = RestAPI.setup()
