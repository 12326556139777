import React, { useState } from "react"
import { API } from "../../App"
import { DeleteUserModal, UserRoleTag, UserEditModal, ReInviteUserModal } from "./"
import { Table, Space, message } from "antd"
import { MailOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import PropTypes from "prop-types"
import { StatusTag, TooltipIconButton } from "../shared"

const statusColorMap = {
	ACTIVE: "green",
	INACTIVE: "red",
	INVITED: "orange",
}

const UserManagementTable = ({ users, setUsers }) => {
	const updateUsers = async () => {
		const { data } = await API.getUsers()
		setUsers(data)
	}

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [isDeleteLoading, setIsDeleteLoading] = useState(false)
	const [isEditModalOpen, setIsEditModalOpen] = useState(false)
	const [isReInviteModalOpen, setIsReInviteModalOpen] = useState(false)
	const [editUser, setEditUser] = useState(null)
	const [userToDelete, setUserToDelete] = useState(null)
	const [userToReInvite, setUserToReInvite] = useState(null)
	const [isReInviteLoading, setIsReInviteLoading] = useState(false)

	const reInviteUser = async (email) => {
		setIsReInviteLoading(true)
		const res = await API.reInviteUser({ email })
		console.log({ res })
		if (res.ok) {
			message.success("User invited")
		} else {
			message.error(res.data.error.message)
		}
		setIsReInviteLoading(false)
		closeReInviteModal()
	}

	const closeEditModal = () => {
		setEditUser(null)
		setIsEditModalOpen(false)
	}

	const openReInviteModal = (email) => {
		setUserToReInvite(email)
		setIsReInviteModalOpen(true)
	}

	const openEditModal = (user) => {
		setEditUser(user)
		setIsEditModalOpen(true)
	}

	const openDeleteModal = (user) => {
		setUserToDelete(user)
		setIsDeleteModalOpen(true)
	}

	const closeReInviteModal = () => {
		setUserToReInvite(null)
		setIsReInviteModalOpen(false)
	}

	const deleteUser = async (id) => {
		setIsDeleteLoading(true)
		const res = await API.deleteUser(id)
		if (res.ok) {
			setUsers(res.data)
			message.success("User deleted")
		} else {
			message.error(res.error.message)
		}
		setIsDeleteLoading(false)
		setIsDeleteModalOpen(false)
	}

	const columns = [
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Name",
			sorter: (a, b) => {
				if (a.lastName && b.lastName) {
					return a.lastName.localeCompare(b.lastName)
				}
				return 0
			},
			render: (_, { firstName, lastName }) => {
				if (firstName && lastName) {
					return `${lastName}, ${firstName}`
				}
				return null
			},
		},

		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			sorter: (a, b) => {
				const order = ["Active", "Invited", "Inactive"]
				return order.indexOf(a.status) - order.indexOf(b.status)
			},
			render: (_, { status }) => status && <StatusTag statusColorMap={statusColorMap} status={status} />,
		},
		{
			title: "Role",
			dataIndex: "roles",
			key: "roles",
			sorter: (a, b) => {
				const order = ["FlavorX Admin", "FlavorX User"]
				return order.indexOf(a.roles[0].name) - order.indexOf(b.roles[0].name)
			},
			render: (_, { roles }) => <>{roles && roles.map(({ name }) => <UserRoleTag key={name} role={name} />)}</>,
		},
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<Space size="middle">
					{record.status === "Invited" && (
						<TooltipIconButton icon={<MailOutlined />} onClick={() => openReInviteModal(record)} text="Send email" />
					)}
					<TooltipIconButton icon={<EditOutlined />} text="Edit user" onClick={() => openEditModal(record)} />
					<TooltipIconButton icon={<DeleteOutlined />} text="Delete user" onClick={() => openDeleteModal(record)} />
				</Space>
			),
		},
	]

	return (
		<>
			{editUser && (
				<UserEditModal
					isModalOpen={isEditModalOpen}
					handleCancel={closeEditModal}
					user={editUser}
					onSuccess={updateUsers}
				/>
			)}
			{userToDelete && (
				<DeleteUserModal
					handleOk={() => deleteUser(userToDelete.id)}
					isModalOpen={isDeleteModalOpen}
					handleCancel={() => setIsDeleteModalOpen(false)}
					loading={isDeleteLoading}
				/>
			)}
			{userToReInvite && (
				<ReInviteUserModal
					onOk={() => reInviteUser(userToReInvite.email)}
					isOpen={isReInviteModalOpen}
					handleCancel={closeReInviteModal}
					loading={isReInviteLoading}
				/>
			)}

			<Table columns={columns} dataSource={users} rowKey={(user) => user.id} />
		</>
	)
}

TooltipIconButton.propTypes = {
	icon: PropTypes.element.isRequired,
	onClick: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired,
}

UserManagementTable.propTypes = {
	users: PropTypes.array.isRequired,
	setUsers: PropTypes.func.isRequired,
}

export default UserManagementTable
