import { Space, Select, Input, AutoComplete, DatePicker, Button, message, Modal, List, notification } from "antd"
import { presets } from "../../utils/dateRangePreset"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import React, { useState, useEffect } from "react"
import { SearchOutlined } from "@ant-design/icons"
import { API } from "../../App"
import { useNavigate, useLocation } from "react-router-dom"
import { getFormattedDate } from "../../utils/formatSearchDates"

const { Option } = Select
const { RangePicker } = DatePicker

const SearchBar = (props) => {
	console.log({ props })
	const navigate = useNavigate()
	const { search } = useLocation()
	let { dateRangeStart, dateRangeEnd } = search
	const initialStartDateRange = getFormattedDate(dateRangeStart)
	const initialEndDateRange = getFormattedDate(dateRangeEnd)
	initialStartDateRange
		? (dateRangeStart = dayjs(initialStartDateRange).format("MM/DD/YYYY"))
		: (dateRangeStart = dayjs().subtract(1, "week").format("MM/DD/YYYY"))
	initialEndDateRange
		? (dateRangeEnd = dayjs(initialEndDateRange).format("MM/DD/YYYY"))
		: (dateRangeEnd = dayjs().format("MM/DD/YYYY"))

	if (!dateRangeStart) dateRangeStart = dayjs().subtract(1, "week")
	if (!dateRangeEnd) dateRangeEnd = dayjs()

	const [options, setOptions] = useState([])
	const [storeNames, setStoreNames] = useState([])
	const [searchType, setSearchType] = useState(props.hideCustomerSearch ? "portal_number" : "customer_name")
	const [selectedSearchValue, setSelectedSearchValue] = useState("")
	const [filteredDateRange, setFilteredDateRange] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [stores, setStores] = useState([])

	useEffect(() => {
		API.getStoreNames().then((res) => {
			const filteredData = res.data
				.map((d) => ({
					value: d,
				}))
				.filter((d) => d.value)
			setStoreNames(filteredData)
			setOptions(filteredData)
		})
	}, [])

	const openNotificationWithIcon = (type, message) => {
		notification[type]({
			message: "",
			description: message,
		})
	}
	return (
		<Space
			direction="horizontal"
			style={{ float: props.hideRangePicker ? "right" : "left", marginTop: props.hideRangePicker ? 10 : 5 }}
		>
			<Select defaultValue={"customer_name"} onChange={(val) => setSearchType(val)} style={{ width: 200 }}>
				{!props.hideCustomerSearch && <Option value="customer_name">Customer Name</Option>}
				<Option value="location_id">Location ID</Option>
				<Option value="customer_number">Customer Number</Option>
				<Option value="store_number">Store Number</Option>
				<Option value="serial_number">Dispenser SN</Option>
			</Select>

			{searchType === "customer_name" && (
				<AutoComplete
					options={options}
					style={{ width: 200 }}
					placeholder="Search"
					onSelect={(val) => setSelectedSearchValue(val)}
					onSearch={(val) => {
						let data = storeNames.filter((d) => d.value.toLowerCase().includes(val?.toLowerCase()))
						setOptions(data)
					}}
				/>
			)}
			{searchType !== "customer_name" && (
				<Input style={{ width: 200 }} placeholder="Search" onChange={(e) => setSelectedSearchValue(e.target.value)} />
			)}

			{!props.hideRangePicker && (
				<RangePicker
					presets={presets}
					value={filteredDateRange}
					format="MM/DD/YYYY"
					onChange={(val) => setFilteredDateRange(val)}
				/>
			)}

			<Button
				onClick={() => {
					if (selectedSearchValue === "") {
						openNotificationWithIcon("error", "Please select a valid search criteria")
						return
					}
					if (searchType === "customer_name" && filteredDateRange === null && dateRangeStart === null) {
						openNotificationWithIcon("error", "Please select a valid date range")
						return
					}

					let dateRangeStr =
						!props.hideRangePicker && filteredDateRange
							? `&dateRangeStart=${filteredDateRange[0].format(
									"MM/DD/YYYY",
								)}&dateRangeEnd=${filteredDateRange[1].format("MM/DD/YYYY")}`
							: `&dateRangeStart=${dayjs(dateRangeStart).format("MM/DD/YYYY")}&dateRangeEnd=${dayjs(
									dateRangeEnd,
								).format("MM/DD/YYYY")}`
					if (searchType === "customer_name") {
						navigate(`/dashboard?customerName=${selectedSearchValue}${dateRangeStr}`)
						window.location.reload()
					} else if (searchType === "customer_number") {
						API.getFilteredStores({
							where: { and: [{ customer_number: selectedSearchValue }, { hierarchyLevel1: { neq: "" } }] },
							fields: { condensed_log: false },
							limit: 1,
						}).then((res) => {
							if (res.data.length === 0) {
								message.error("Customer not found")
							} else {
								let customerName = res.data[0].hierarchyLevel1
								if (!customerName) {
									message.error("Customer not found")
								} else {
									navigate(`/dashboard?customerName=${customerName}${dateRangeStr}`)
									window.location.reload()
								}
							}
						})
					} else if (searchType === "store_number") {
						API.getFilteredStores({
							where: { store_number: selectedSearchValue },
							fields: { condensed_log: false },
						}).then((res) => {
							if (res.data.length === 0) {
								message.error("Store not found")
							} else if (res.data.length === 1) {
								navigate(`/store?dispenserID=${res.data[0].id}${dateRangeStr}`)
								window.location.reload()
							} else {
								setStores(res.data)
								setIsModalOpen(true)
							}
						})
					} else {
						navigate(`/store?${searchType}=${selectedSearchValue}${dateRangeStr}`)
						window.location.reload()
					}
				}}
				icon={<SearchOutlined />}
				size="medium"
				type="primary"
			/>

			{isModalOpen && (
				<Modal
					title="Select Store"
					open={isModalOpen}
					onOk={() => setIsModalOpen(false)}
					onCancel={() => setIsModalOpen(false)}
				>
					<List
						bordered
						dataSource={stores}
						renderItem={(store) => {
							let dateRangeStr = !props.hideRangePicker
								? `&dateRangeStart=${filteredDateRange[0].format(
										"MM/DD/YYYY",
									)}&dateRangeEnd=${filteredDateRange[1].format("MM/DD/YYYY")}`
								: ""
							let storeTitle = store.hierarchyLevel1
							if (store.hierarchyLevel2) storeTitle += ` > ${store.hierarchyLevel2}`
							if (store.hierarchyLevel3) storeTitle += ` > ${store.hierarchyLevel3}`
							if (store.hierarchyLevel4) storeTitle += ` > ${store.hierarchyLevel4}`
							if (store.hierarchyLevel5) storeTitle += ` > ${store.hierarchyLevel5}`
							storeTitle += ` > ${store.store_name}`
							return (
								<List.Item
									actions={[
										<Button
											type="link"
											key="view-store"
											onClick={() => {
												navigate(`/store?dispenserID=${store.id}${dateRangeStr}`)
												window.location.reload()
											}}
										>
											View Store
										</Button>,
									]}
								>
									{storeTitle}
								</List.Item>
							)
						}}
					/>
				</Modal>
			)}
		</Space>
	)
}

SearchBar.propTypes = {
	hideCustomerSearch: PropTypes.bool,
	hideRangePicker: PropTypes.bool,
	dateRangeStart: PropTypes.string,
	dateRangeEnd: PropTypes.string,
}

export default SearchBar
