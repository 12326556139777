import React, { useState, useEffect } from "react"
import { useNavigate, Link, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { Space, Select, AutoComplete, Breadcrumb } from "antd"
import { API } from "../../App"

const { Option } = Select
const _ = require("lodash")

const HierarchySelector = (props) => {
	const location = useLocation()
	const pathSnippets = location.pathname.split("/").filter((i) => i)
	const navigate = useNavigate()
	const [storeHierarchy, setStoreHierarchy] = useState([])
	const [storeNames, setStoreNames] = useState([])
	const [hierarchyLevel, setHierarchyLevel] = useState(props.hierarchyLevel || 0)
	const [hierarchyLevels, setHierarchyLevels] = useState(props.hierarchyLevels || {})
	const [filteredStores, setFilteredStores] = useState(props.filteredStores || [])
	const [storeData, setStoreData] = useState(props.storeData || [])
	const [options, setOptions] = useState([])
	const [selectedHierarchyLevel, setSelectedHierarchyLevel] = useState(null) // Add this state to keep track of the selected hierarchy level.
	const [selectedHierarchyLevel1, setSelectedHierarchyLevel1] = useState()
	const [breadcrumbs, setBreadcrumbs] = useState([]) // Add breadcrumbs state here

	useEffect(() => {
		let breadcrumbItems = [{ text: "Home", link: "/", level: "0" }] // Initial breadcrumb item for Home.
		let queryStringResult = `customerName=${hierarchyLevels.hierarchyLevel1}`
		for (const hierarchyLevelItem in hierarchyLevels) {
			if (Object.hasOwnProperty.call(hierarchyLevels, hierarchyLevelItem)) {
				if (hierarchyLevelItem !== "hierarchyLevel1") {
					queryStringResult += `&${hierarchyLevelItem}=${hierarchyLevels[hierarchyLevelItem]}`
				}

				breadcrumbItems.push({
					level: hierarchyLevelItem,
					text: `${hierarchyLevels[hierarchyLevelItem]}`, // Update the breadcrumb item text with the selected hierarchy level.
					link: `/dashboard?${queryStringResult}&dateRangeStart=${props.filteredDateRange[0].format()}&dateRangeEnd=${props.filteredDateRange[1].format()}`, // Create a link for hierarchy level 1.
				})
			}
		}

		if (hierarchyLevel !== 0) {
			setBreadcrumbs(breadcrumbItems)
		}

		API.getStoreNames().then((res) => {
			const filteredData = res.data
				.map((d) => ({
					value: d,
				}))
				.filter((d) => d.value)
			setStoreNames(filteredData)
			setOptions(filteredData)
		})

		if (hierarchyLevels.hierarchyLevel1 && !selectedHierarchyLevel1) {
			setSelectedHierarchyLevel1(hierarchyLevels.hierarchyLevel1)
		}
	}, [])

	useEffect(() => {
		if (selectedHierarchyLevel1) {
			// @TODO: Create request for getting data for selectors (all regions), not all stores.
			API.streamFilteredSnapshotsData({ where: { hierarchyLevel1: selectedHierarchyLevel1 } }).then((res) => {
				setHierarchyLevel(hierarchyLevel)
				setStoreHierarchy(res.data)
			})
			setHierarchyLevels(hierarchyLevels)
			if (props.onSetHierarchyFilter)
				props.onSetHierarchyFilter({ where: { hierarchyLevel1: selectedHierarchyLevel1 } })
		}
	}, [selectedHierarchyLevel1])

	const buildHierarchy = () => {
		let parentHierarchy = filteredStores[0].hierarchyLevel1
		let results = []
		let lev2 = _.groupBy(filteredStores, (d) => `${d.hierarchyLevel2}`)
		let breadcrumbItems = [{ text: "Home", link: "/", level: "0" }] // Initial breadcrumb item for Home.

		_.forEach(lev2, function (value, key) {
			results.push({
				value: key,
				level: "hierarchyLevel2",
				hierarchy: `${parentHierarchy}|${key}`,
				children: buildChildHierarchy(3, value, `${parentHierarchy}|${key}`),
			})
		})

		let queryStringResult = `customerName=${hierarchyLevels.hierarchyLevel1}`
		for (const hierarchyLevel in hierarchyLevels) {
			if (Object.hasOwnProperty.call(hierarchyLevels, hierarchyLevel)) {
				if (hierarchyLevel !== "hierarchyLevel1") {
					queryStringResult += `&${hierarchyLevel}=${hierarchyLevels[hierarchyLevel]}`
				}

				breadcrumbItems.push({
					level: hierarchyLevel,
					text: `${hierarchyLevels[hierarchyLevel]}`, // Update the breadcrumb item text with the selected hierarchy level.
					link: `/dashboard?${queryStringResult}&dateRangeStart=${props.filteredDateRange[0].format()}&dateRangeEnd=${props.filteredDateRange[1].format()}`, // Create a link for hierarchy level 1.
				})
			}
		}

		setBreadcrumbs(breadcrumbItems) // Update the breadcrumb items state.

		return results
	}

	const buildChildHierarchy = (level, values, parentHierarchy) => {
		const levs = _.groupBy(values, (d) => d[`hierarchyLevel${level}`])
		const results = []
		_.forEach(levs, function (value, key) {
			if (key == "") {
				//get the stores
				value.forEach((a) => {
					a.stores.forEach((b) => {
						results.push({
							value: b.name,
							storeId: b.id,
							level: `hierarchyLevelStore`,
						})
					})
				})
			} else {
				let obj = {
					value: key,
					level: `hierarchyLevel${level}`,
					hierarchy: `${parentHierarchy}|${key}`,
				}
				if (level < 5) {
					let children = buildChildHierarchy(level + 1, value, `${parentHierarchy}|${key}`)
					if (children !== null || children?.length > 0) obj.children = children
				}
				results.push(obj)
			}
		})

		return results
	}
	useEffect(() => {
		if (!filteredStores || hierarchyLevel === 0 || filteredStores?.length === 0) return

		const isFiltredStoresEqual = _.isEqual(filteredStores, props.filteredStores)
		const newHierarchy = buildHierarchy()
		const isHierarchyLevelEqual = _.isEqual(newHierarchy, props.tableDataHierarchy)

		if (props.onSetFilteredStores && !isFiltredStoresEqual) props.onSetFilteredStores(filteredStores)
		if (props.onSetTableDataHierarchy && !isHierarchyLevelEqual) {
			props.onSetTableDataHierarchy(newHierarchy)
		}
	}, [filteredStores, hierarchyLevel])

	useEffect(() => {
		if (props.onSetHierarchyLevel) props.onSetHierarchyLevel(hierarchyLevel)
	}, [hierarchyLevel])

	useEffect(() => {
		if (!props.onSetHierarchyName) return
		let levels = Object.values(hierarchyLevels)
		if (levels?.length === 1) props.onSetHierarchyName(_.head(levels))
		else if (levels?.length > 1) props.onSetHierarchyName(`${_.head(levels)}-${_.last(levels)}`)
	}, [hierarchyLevels])

	useEffect(() => {
		if (filteredStores && filteredStores?.length == 0 && props.filteredStores?.length > 0)
			setFilteredStores(props.filteredStores)
	}, [props.filteredStores])
	useEffect(() => {
		if (storeData?.length == 0 && props.storeData && props.storeData?.length > 0) setStoreData(props.storeData)
	}, [props.storeData])

	const handleBreadcrumbClick = (index) => {
		const breadcrumbData = breadcrumbs[index]

		const { link: breadcrumbLink, text: breadcrumbVal } = breadcrumbData

		const sliceIndex = index + 1

		const newBreadcrumbs = breadcrumbs.slice(0, sliceIndex)

		setSelectedHierarchyLevel(breadcrumbVal) // Update the selected hierarchy level.

		let hierarchyObj = {}
		for (const level in hierarchyLevels) {
			if (Object.hasOwnProperty.call(hierarchyLevels, level)) {
				const isExistLevel = newBreadcrumbs?.find((b) => b.text === hierarchyLevels[level])
				if (isExistLevel) {
					hierarchyObj[level] = hierarchyLevels[level]
				}
			}
		}

		let filters = []
		for (let j = 0; j <= index - 1; j++) {
			let filter = {}
			filter[`hierarchyLevel${j + 1}`] = hierarchyObj[`hierarchyLevel${j + 1}`]
			filters.push(filter)
		}

		setHierarchyLevel(index)
		setHierarchyLevels(hierarchyObj)
		if (props?.onSetHierarchyFilter) props.onSetHierarchyFilter({ where: { and: filters } })

		setBreadcrumbs(newBreadcrumbs)

		// API.streamFilteredSnapshotsData({ where: { and: filters } }).then((res) => setFilteredStores(res.data))
	}
	return (
		<Space direction="horizontal">
			<Breadcrumb>
				{breadcrumbs.map((item, index) => (
					<Breadcrumb.Item key={index} onClick={() => handleBreadcrumbClick(index)}>
						{index === breadcrumbs?.length - 1 ? ( // If it's the last breadcrumb item, just display the text without a link.
							item.text
						) : (
							<Link to={item.link}>{item.text}</Link>
						)}
					</Breadcrumb.Item>
				))}
			</Breadcrumb>
			<AutoComplete
				options={options}
				style={{ width: 200 }}
				placeholder="Search"
				value={selectedHierarchyLevel1}
				onSelect={(val) => setSelectedHierarchyLevel1(val)}
				onSearch={(val) => {
					let data = storeNames.filter((d) => d.value.toLowerCase().includes(val?.toLowerCase()))
					setOptions(data)
				}}
			/>
			{[...Array(5).keys()].map((i) => {
				if (i <= hierarchyLevel && i > 0) {
					let storeHierarchyLevel = false

					// Get hierarchy objects
					const filteredHierarchyObjects = _.sortBy(
						_.unionBy(storeHierarchy, `hierarchyLevel${i + 1}`),
						`hierarchyLevel${i + 1}`,
					).map((d) => {
						if (d[`hierarchyLevel${i + 1}`])
							return { val: d[`hierarchyLevel${i + 1}`], label: d[`hierarchyLevel${i + 1}`] }
					})

					// Get parent hierarchy snapshot to add any stores to the options
					const parentHierarchyObject = storeHierarchy?.find((d) => {
						let match = d.hierarchyLevel1 === hierarchyLevels.hierarchyLevel1

						if (i === 1 && match) {
							return (
								d.hierarchyLevel2 == "" && d.hierarchyLevel3 == "" && d.hierarchyLevel4 == "" && d.hierarchyLevel5 == ""
							)
						} else if (i == 2 && match) {
							return d.hierarchyLevel2 == hierarchyLevels.hierarchyLevel2 && d.hierarchyLevel3 == ""
						} else if (i == 3 && match) {
							return (
								d.hierarchyLevel2 == hierarchyLevels.hierarchyLevel2 &&
								d.hierarchyLevel3 == hierarchyLevels.hierarchyLevel3 &&
								d.hierarchyLevel4 == ""
							)
						} else if (i == 4 && match) {
							return (
								d.hierarchyLevel2 == hierarchyLevels.hierarchyLevel2 &&
								d.hierarchyLevel3 == hierarchyLevels.hierarchyLevel3 &&
								d.hierarchyLevel4 == hierarchyLevels.hierarchyLevel4 &&
								d.hierarchyLevel5 == ""
							)
						} else if (i == 5 && match) {
							return (
								d.hierarchyLevel2 == hierarchyLevels.hierarchyLevel2 &&
								d.hierarchyLevel3 == hierarchyLevels.hierarchyLevel3 &&
								d.hierarchyLevel4 == hierarchyLevels.hierarchyLevel4 &&
								d.hierarchyLevel5 == hierarchyLevels.hierarchyLevel5
							)
						}
					})

					let options = parentHierarchyObject?.stores
						? [
								...filteredHierarchyObjects,
								...parentHierarchyObject?.stores.map((d) => ({ val: `|STORE|${d.id}`, label: d.name })),
							]
						: filteredHierarchyObjects

					return (
						<Select
							key={i}
							showSearch
							placeholder={storeHierarchyLevel ? "Select Store" : "Select"}
							value={hierarchyLevels?.[`hierarchyLevel${i + 1}`] || ""}
							onChange={(val) => {
								setSelectedHierarchyLevel(val) // Update the selected hierarchy level.
								if (val.includes("|STORE|")) {
									storeHierarchyLevel = true
									val = val.replace("|STORE|", "")
								} else {
									storeHierarchyLevel = false
								}

								if (storeHierarchyLevel && !props.blockNavigation) {
									if (props.filteredDateRange)
										navigate(
											`/store?dispenserID=${val}&dateRangeStart=${props.filteredDateRange[0].format()}&dateRangeEnd=${props.filteredDateRange[1].format()}`,
										)
									else navigate(`/store/${val}`)
								} else if (storeHierarchyLevel && props.blockNavigation) {
									if (props.onSetHierarchyFilter) props.onSetHierarchyFilter({ where: { id: val } })
									API.streamFilteredSnapshotsData({ where: { id: val } }).then((res) => setFilteredStores(res.data))
								} else {
									const hierarchyIndex = i + 1
									let hierarchyObj = {}

									let filters = []
									for (let j = 0; j <= i; j++) {
										let filter = {}
										const isLastLevel = j + 1 === hierarchyIndex
										const filterValue = isLastLevel ? val : hierarchyLevels[`hierarchyLevel${j + 1}`]

										hierarchyObj[`hierarchyLevel${j + 1}`] = filterValue
										filter[`hierarchyLevel${j + 1}`] = filterValue
										filters.push(filter)

										if (isLastLevel) break
									}

									setHierarchyLevel(hierarchyIndex)
									setHierarchyLevels(hierarchyObj)
									if (props.onSetHierarchyFilter) props.onSetHierarchyFilter({ where: { and: filters } })

									let breadcrumbsQueryStringResult = `customerName=${hierarchyObj.hierarchyLevel1}`

									for (const hierarchyLevel in hierarchyObj) {
										if (Object.hasOwnProperty.call(hierarchyObj, hierarchyLevel)) {
											if (hierarchyLevel !== "hierarchyLevel1") {
												breadcrumbsQueryStringResult += `&${hierarchyLevel}=${hierarchyObj[hierarchyLevel]}`
											}
										}
									}

									let isSameHierarchyLevel = false
									let breadcrumbItems = breadcrumbs.map((el) => {
										if (el.level === `hierarchyLevel${i + 1}`) {
											isSameHierarchyLevel = true
											return {
												level: `hierarchyLevel${i + 1}`,
												text: `${val}`,
												link: `/dashboard?${breadcrumbsQueryStringResult}&dateRangeStart=${props.filteredDateRange[0].format()}&dateRangeEnd=${props.filteredDateRange[1].format()}`, // Create a link for hierarchy level 1.
											}
										}
										return el
									})
									if (!isSameHierarchyLevel) {
										breadcrumbItems.push({
											level: `hierarchyLevel${i + 1}`,
											text: `${val}`, // Update the breadcrumb item text with the selected hierarchy level.
											link: `/dashboard?${breadcrumbsQueryStringResult}&dateRangeStart=${props.filteredDateRange[0].format()}&dateRangeEnd=${props.filteredDateRange[1].format()}`, // Create a link for hierarchy level 1.
										})
									}

									setBreadcrumbs(breadcrumbItems)

									// API.streamFilteredSnapshotsData({ where: { and: filters } }).then((res) =>
									// 	setFilteredStores(res.data),
									// )
									navigate(
										`/dashboard?${breadcrumbsQueryStringResult}&dateRangeStart=${props.filteredDateRange[0].format()}&dateRangeEnd=${props.filteredDateRange[1].format()}`,
									)
								}
							}}
							filterOption={(input, option) => option.children?.toLowerCase().includes(input.toLowerCase())}
							style={{ width: 200 }}
						>
							{_.uniqBy(options, "val")
								.sort(({ label: valueA }, { label: valueB }) => {
									const regex = /\d+/

									const numA = parseInt(valueA.match(regex))
									const numB = parseInt(valueB.match(regex))

									if (isNaN(numA) && isNaN(numB)) {
										return valueA.localeCompare(valueB)
									} else if (isNaN(numA)) {
										return 1
									} else if (isNaN(numB)) {
										return -1
									} else {
										return numA - numB
									}
								})

								.map((d) => {
									if (d) {
										d.label = d.label.replace(/\b0+(\d+)/g, "$1")
										return (
											<Option key={d.id} value={d.val}>
												{d.label}
											</Option>
										)
									}
								})}
						</Select>
					)
				} else return
			})}
		</Space>
	)
}

HierarchySelector.propTypes = {
	onSetHierarchyFilter: PropTypes.func,
	tableDataHierarchy: PropTypes.array,
	filteredDateRange: PropTypes.array,
	blockNavigation: PropTypes.bool,
	filteredStores: PropTypes.array,
	storeData: PropTypes.array,
	hierarchyLevel: PropTypes.number,
	hierarchyLevels: PropTypes.object,
	onSetFilteredStores: PropTypes.func,
	onSetTableDataHierarchy: PropTypes.func,
	onSetHierarchyLevel: PropTypes.func,
	onSetHierarchyName: PropTypes.func,
}

export default HierarchySelector
